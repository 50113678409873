// Genres.js
const genres = [
    "Rock",
    "Pop",
    "Hip-Hop",
    "Jazz",
    "Classical",
    "Electronic",
    "Blues",
    "Reggae",
    "Country",
    "R&B",
    "Metal",
    "Folk",
    "Indie",
    "Punk",
    "Soul",
    "Disco",
    "Techno",
    "House",
    "Dubstep",
    "Trance",
    "Ambient",
    "Ska",
    "Gospel",
    "Latin",
    "Salsa",
    "Bossa Nova",
    "Afrobeat",
    "K-Pop",
    "J-Pop",
    "EDM",
    "Dancehall",
    "Bluegrass",
    "Grunge",
    "Hardcore",
    "Funk",
    "Progressive Rock",
    "Alternative",
    "Experimental",
    "New Wave",
    "Synthwave",
    "Industrial",
    "Trip-Hop",
    "Chillout",
    "Soundtrack",
    "Opera",
    "Acoustic",
    "World Music",
    "Bollywood",
    "Celtic",
    "Flamenco",
    "Tango",
    "Reggaeton",
    "Zydeco",
    "Trap",
    "Drum and Bass",
    "Garage",
    "Lo-fi",
    "Shoegaze",
    "Post-Rock",
    "Emo",
    "Screamo",
    "Chiptune",
    "Vaporwave",
    "Math Rock",
    "Dub",
    "Psytrance",
    "Hardstyle",
    "Goth",
    "Darkwave"
  ];
  
  export default genres;
  