import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button,Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import countriesArray from './CountriesLibrary';
import PaymentScreen from '../Screens/PaymentScreen';
import { AlignStart } from 'react-bootstrap-icons';
import './OrganizerAccountDetails.css';
import ResetPassword from './ResetPassword';


import { Elements } from '@stripe/react-stripe-js';
import stripeKey from '../Components/StripeKey';
import { loadStripe } from '@stripe/stripe-js';
import GetSubscriptionInfo from './GetSubscriptionInfo';
import CancelSubscriptionAndDeleteAccount from './CancelSubscriptionAndDeleteAccount';

const OrganizerAccountDetails = ({ onClose, userId }) => {
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [bandName, setBandName] = useState('');
  const [musicGenre, setMusicGenre] = useState('');
  const [gigRadius, setGigRadius] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subscription1, setRadioOption1] = useState(true);
  const [subscription2, setRadioOption2] = useState(false);
  const [discount, setDiscount] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountry, setBillingCountry] = useState('US');
  const [contactInformation, setContactInfo] = useState('');
  const [countyInfo, setCounty] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTwoFactorModalVisible, setIsTwoFactorModalVisible] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [subscriptionAmount, setSubscriptionAmount] = useState(999);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const stripePromise = loadStripe(stripeKey);
  const [isProcessing,Processing] = useState(false);
  const [infoFilled,setDetailsFilled] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const [stripeCustomerID, setStripeCustomerID] = useState(null);
  const [CustomerIDerror, setCustomerIDError] = useState(null);

  useEffect(() => {

    fetchStripeCustomerID();
    // Fetch organizer data
    const fetchOrganizerData = async () => {
      setLoading(true);
        try {
          const response = await axios.get(`http://34.232.96.254/GetAccountDetailsForOrganizer.php?userId=${userId}`);
          const data = response.data;
          console.log("Organizer Data: "+JSON.stringify(data));
          setUsername(data.username);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.email);
          setWebsite(data.website);
          setPassword(data.password);
          setRepeatPassword(data.password);
          setStreetAddress(data.streetAddress);
          setZipCode(data.zipCode);
          setCity(data.city);
          setState(data.state);
          setPhoneNumber(data.phoneNumber);
          setBillingFirstName(data.billingFirstName);
          setBillingLastName(data.billingLastName);
          setBillingEmail(data.billingEmail);
          setBillingPhone(data.billingPhone);
          setBillingAddress(data.billingAddress);
          setBillingZipCode(data.billingZipCode);
          setBillingCity(data.billingCity);
          setBillingState(data.billingState);
          setBillingCountry(data.billingCountry);
          if(response!=null)
          {
            setLoading(false);
          }
      
          if (data.subscription === 'Organizer Subscription 1') {
            setRadioOption1(true);
            setRadioOption2(false);
            setSubscriptionAmount(10000);
          } else {
            setRadioOption1(false);
            setRadioOption2(true);
            setSubscriptionAmount(999);
          }
        } catch (error) {
          console.error('Error fetching organizer data:', error);
        }
      };
      
    fetchOrganizerData();
  }, [userId]);

  const handleCloseModal = () => {
    setErrorMessage(null);
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
  };

  const handlePaymentFailure = (errorMessage) => {
    setPaymentError(errorMessage);
  };

  const handleRadio1Press = () => {
    setRadioOption1(true);
    setRadioOption2(false);
    setSubscriptionAmount(10000);
  };

  const handleRadio2Press = () => {
    setRadioOption2(true);
    setRadioOption1(false);
    setSubscriptionAmount(999);
  };

  const copyPersonalToBilling = () => {
    setBillingFirstName(firstName);
    setBillingLastName(lastName);
    setBillingEmail(email);
    setBillingPhone(phoneNumber);
    setBillingAddress(streetAddress);
    setBillingZipCode(zipCode);
    setBillingCity(city);
    setBillingState(state);
    setBillingCountry(selectedCountry);
  };

  const updateOrganizer = async () => {
    let subscription;
    if (subscription1) {
        subscription = "Organizer Subscription 1";
    } else if (subscription2) {
        subscription = "Organizer Subscription 2";
    } else {
        return;
    }

    const organizerData = {
        userId,
        username,
        firstName,
        lastName,
        email,       
        streetAddress,
        zipCode,
        city,
        state,
        website,
        phoneNumber,
        billingFirstName,
        billingLastName,
        billingEmail,
        billingPhone,
        billingAddress,
        billingZipCode,
        billingCity,
        billingState,
        billingCountry,
        subscription
    };

    try {
        const response = await axios.post('http://34.232.96.254/UpdateOrganizerAccountDetails.php', organizerData);

        if (response.data.success) {
            console.log('Update successful:', response.data.message);
            window.alert("Update Successful");
            Processing(false);
        } else {
            console.log('Update failed:', response.data.message);
            window.alert("Update failed: " + response.data.message);
            setErrorMessage(response.data.message);
            Processing(false);
        }
    } catch (error) {
        console.error('Error updating organizer:', error);
        setErrorMessage('An error occurred while updating the organizer details. Please try again later.');
        Processing(false);
    }
  };

  const billingDetails = {
    name: billingFirstName + " " + billingLastName,
    email: billingEmail,
    phone: billingPhone,
    address: {
      city: billingCity,
      country: billingCountry,
      line1: billingAddress,
      line2: '',
      postalCode: billingZipCode,
    },
  };

  const  PriceSelected =()=>{
    if(subscription1==true)
       {
      return 'price_1PipgyADRnUTSqmNP3sE0SJA'; // 100 yearly
       }

    if(subscription2==true)
    {
       return 'price_1PipjDADRnUTSqmNQeLLGV6A'; //9.99 monthly
    }
 };

 const fetchStripeCustomerID = async () => {
  const formData = new FormData();
  formData.append('user_id', userId);
  formData.append('role', 'organizer');
  

  try {
    const response = await axios.post(
      'http://34.232.96.254/GetStripeCustomerID.php',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    
    console.log("Fetched response Stripe Customer ID " + JSON.stringify(response.data));
    

    console.log("Fetched response Stripe Customer ID "+JSON.stringify(response.data));

    console.log("Fetched Customer ID :"+response.data.Stripe_CustomerID);

    // Handle the response and extract Stripe_CustomerID
    if (response.data.Stripe_CustomerID) {
      setStripeCustomerID(response.data.Stripe_CustomerID);
    } else if (response.data.error) {
      setCustomerIDError(response.data.error);
    }
  } catch (err) {
    setCustomerIDError('An error occurred while fetching data.');
  }
};

 return (
  <div style={styles.container}>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    ) : (
      <>
        <h2 style={styles.title}>Organizer Registration</h2>

        <h3 style={styles.sectionTitle}>Personal Information</h3>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <label>Name</label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>First Name</label>
            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Last Name</label>
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Contact Info</label>
            <input type="text" value={contactInformation} onChange={(e) => setContactInfo(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>E-mail</label>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Website</label>
            <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} />
          </div>
          <ResetPassword role={'organizer'} />
          <div style={styles.inputContainer}>
            <label>Phone Number</label>
            <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </div>

          <h3 style={styles.sectionTitle}>Address</h3>
          <div style={styles.inputContainer}>
            <label>Street Address</label>
            <input type="text" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Zip Code</label>
            <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>City</label>
            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>State</label>
            <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Country</label>
            <select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              {countriesArray.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <h3 style={styles.sectionTitle}>Billing Address</h3>
        <div style={styles.formContainer}>
          <div style={styles.inputContainer}>
            <label>Billing First Name</label>
            <input type="text" value={billingFirstName} onChange={(e) => setBillingFirstName(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing Last Name</label>
            <input type="text" value={billingLastName} onChange={(e) => setBillingLastName(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing E-mail</label>
            <input type="text" value={billingEmail} onChange={(e) => setBillingEmail(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing Phone Number</label>
            <input type="text" value={billingPhone} onChange={(e) => setBillingPhone(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing Address</label>
            <input type="text" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing Zip Code</label>
            <input type="text" value={billingZipCode} onChange={(e) => setBillingZipCode(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing City</label>
            <input type="text" value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing State</label>
            <input type="text" value={billingState} onChange={(e) => setBillingState(e.target.value)} />
          </div>
          <div style={styles.inputContainer}>
            <label>Billing Country</label>
            <select value={billingCountry} onChange={(e) => setBillingCountry(e.target.value)}>
              {countriesArray.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        <h3 style={styles.sectionTitle}>Payment</h3>
        <div>
          <Elements stripe={stripePromise}>
            <PaymentScreen
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentFailure={handlePaymentFailure}
              customerInfo={billingDetails}
              priceId={PriceSelected} // Replace with your actual price ID from Stripe
              isAccountDetails={true}
              Processing={Processing}
              isProcessing={isProcessing}
              infoFilled={infoFilled}
            />
          </Elements>
        </div>
        <button style={styles.copyButton} onClick={copyPersonalToBilling}>
          Copy Personal Information to Billing
        </button>

        <h3 style={styles.sectionTitle}>Subscription</h3>
        <div style={styles.subscriptionContainer}>
        <GetSubscriptionInfo stripeCustomerID={stripeCustomerID} role={'organizer'}/>
        </div>

        <div style={styles.button}>
          <button onClick={updateOrganizer}>Update Organizer Details</button>
        </div>

        <CancelSubscriptionAndDeleteAccount user_id={userId} role={'organizer'} Stripe_CustomerID={stripeCustomerID} />

        {/* Error Modal */}
        <Modal show={!!errorMessage} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )}
  </div>
);
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '90%',
    maxWidth: '900px',
    margin: 'auto',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
    marginTop: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    marginBottom: '15px',
  },
  button:{
    padding: '10px',
    paddingTop: '10px',
   
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
  },
  select: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
  },
  copyButton: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioLabel: {
    marginBottom: '10px',
    fontSize: '16px',
  },
  radioInput: {
    marginRight: '10px',
  },
  submitButton: {
    marginTop: '20px',
  },
};




export default OrganizerAccountDetails;
