import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FaInfoCircle, FaEdit, FaBan, FaCopy, FaTrash } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './OrganizerEventList.css';
import DuplicateGigComponent from './DuplicateGigComponent';
import EditGigComponent from './EditGigComponent';

const OrganizerEventList = ({ UserIDnumber, token }) => {
  const [eventPages, setEventPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewedCount, setViewedCount] = useState(0);

  const itemsPerPage = 10;

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await fetch(`http://34.232.96.254/GetGigsByOrganizerID.php?OrganizerID=${UserIDnumber}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Sort events by `Gig_start_date` from newest to oldest
        const sortedEvents = data.sort((a, b) => new Date(b.Gig_start_date) - new Date(a.Gig_start_date));
  
        // Split into pages
        const pages = [];
        for (let i = 0; i < sortedEvents.length; i += itemsPerPage) {
          pages.push(sortedEvents.slice(i, i + itemsPerPage));
        }
  
        setEventPages(pages);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch events:', errorData.error || 'Unknown error');
      }
    } catch (error) {
      console.error('Error fetching events:', error.message || 'Unknown error');
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchEvents();
  }, [UserIDnumber, token]);

  const handleDeleteEvent = async (event) => {
    try {
      const response = await fetch(`http://34.232.96.254/DeleteGigByID.php?Gig_id=${event.Gig_id}`, {
        method: 'DELETE',
        headers: {
          
        },
      });

      if (response.ok) {
        fetchEvents();
      } else {
        const errorData = await response.json();
        console.error('Failed to delete event:', errorData.error || 'Unknown error');
      }
    } catch (error) {
      console.error('Error deleting event:', error.message || 'Unknown error');
    }
  };

  const fetchEventDetails = async (event) => {
    try {
      const response = await fetch(`http://34.232.96.254/GetGigInfo.php?Gig_id=${event.Gig_id}`);
      const data = await response.json();
      setViewedCount(parseInt(data.Gig_Views));
    } catch (error) {
      console.error('Error fetching event details:', error.message || 'Unknown error');
    }
  };

  function createImageUrl(base64String) {
    // Check if the base64 string already contains the prefix
    if (base64String.startsWith('data:image/')) {
        return base64String; // No need to modify
    }
    
    // Define known base64 prefix for JPEG images
    const jpegPrefix = 'data:image/jpeg;base64,';

    // Define known base64 prefixes for different image types
    const imageTypes = {
        'iVBORw0KGgo': 'png',  // PNG base64 prefix
        '/9j/4': 'jpeg',      // JPEG base64 prefix
        'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
    };

    // Detect image type
    let imageType = '';
    for (const [prefix, type] of Object.entries(imageTypes)) {
        if (base64String.startsWith(prefix)) {
            imageType = type;
            break;
        }
    }

    // If no known image type is found, assume JPEG
    if (!imageType) {
        imageType = 'jpeg';
    }

    // Return the data URL with the detected or default image type
    return `data:image/${imageType};base64,${base64String}`;
}

function convertToStandardTime(datetimeString) {
  // Parse the input datetime string into a Date object
  const date = new Date(datetimeString.replace(' ', 'T') + 'Z');
  
  // Use Intl.DateTimeFormat to format the date into 12-hour format with AM/PM
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  
  // Format the date
  const formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
  
  return formattedTime;
}

// Example usage
const datetimeString = '2024-06-20 14:02:00';
console.log(convertToStandardTime(datetimeString)); // Outputs: "6/20/2024, 2:02:00 PM"
function extractDateOnly(datetimeString) {
  // Parse the input datetime string into a Date object
  const date = new Date(datetimeString);

  // Use Intl.DateTimeFormat to format the date only
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  // Format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}

function convertMilitaryToStandard(militaryTime) {
  // Split the military time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = militaryTime.split(':').map(Number);

  // Determine the period (AM/PM)
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const standardHours = hours % 12 || 12; // Use 12 for midnight or noon

  // Format the time in 12-hour format with leading zeroes
  const formattedTime = `${standardHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${period}`;

  return formattedTime;
}

  const renderEvent = (event) => (
    <div key={event.Gig_id} className="event-card">
    <div className={`status-indicator ${getStatusIndicatorClass(event.Gig_Status)}`}>
      {event.Gig_Status}
    </div>
    <h3>{event.Gig_Title}</h3>
    <p><strong>Date and Time:</strong> {extractDateOnly(event.Gig_start_date)+" "+convertMilitaryToStandard(event.Gigs_start_time)} - {extractDateOnly(event.Gig_end_date)+" "+convertMilitaryToStandard(event.Gigs_end_time)} </p>
    <p><strong>Location:</strong> {event.Gig_Location_Address}</p>
    <img src={createImageUrl(event.Gig_Image)} alt="Gig" className="gig-image" />
    <div className="button-groupEventList">
        <FaInfoCircle
          className="icon-button"
          title="Details"
          onClick={() => { setSelectedEvent(event); setShowDetailsModal(true); fetchEventDetails(event); }}
        />
        <FaEdit
          className="icon-button"
          title="Edit"
          onClick={() => { setSelectedEvent(event); setShowEditModal(true); }}
        />
        <FaBan
          className="icon-button"
          title="Cancel"
          onClick={() => { setSelectedEvent(event); setShowCancelModal(true); }}
        />
        <FaCopy
          className="icon-button"
          title="Duplicate"
          onClick={() => { setSelectedEvent(event); setShowDuplicateModal(true); }}
        />
        <FaTrash
          className="icon-button"
          title="Delete"
          onClick={() => { setSelectedEvent(event); setShowDeleteModal(true); }}
        />
      </div>
    </div>
  );
  const getStatusIndicatorClass = (status) => {
    switch (status) {
      case 'Open':
        return 'open';
      case 'Closed':
        return 'closed';
      case 'Canceled':
      case 'Expired':
        return 'canceled'; // Use grey for both Canceled and Expired
        case 'Scheduled':
            return 'scheduled';
      default:
        return '';
    }
  };
  return (
    <div className="organizer-event-list">
      {loading ? (
        <Spinner animation="border" />
      ) : (
        eventPages.map((page, index) => (
          <div key={index} className="event-page">
            {page.map(renderEvent)}
          </div>
        ))
      )}

      {/* Details Modal */}
      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Viewed:</strong> {viewedCount}</p>
          <p><strong>Registrations:</strong> {selectedEvent?.Gig_Confirmed_Registrations}</p>
        </Modal.Body>
      </Modal>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedEvent && (
          <EditGigComponent
              gigData={selectedEvent} // Pass the selected event data
              UserIDnumber={UserIDnumber} // Pass necessary props
              onHide={() => setShowEditModal(false)}
              onUpdate={()=>fetchEvents()}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Cancel Modal */}
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel the gig?</p>
          <button
            className="btn btn-danger"
            onClick={() => {
              fetch(`http://34.232.96.254/CancelGig.php?Gig_id=${selectedEvent.Gig_id}`);
              setShowCancelModal(false);
              fetchEvents();
            }}
          >
            Yes
          </button>
        </Modal.Body>
      </Modal>

      {/* Duplicate Modal */}
      <Modal show={showDuplicateModal} onHide={() => setShowDuplicateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <DuplicateGigComponent
              gigData={selectedEvent} // Pass the selected event data
              UserIDnumber={UserIDnumber} // Pass necessary props
              onHide={() => setShowDuplicateModal(false)}
              onUpdate={()=>fetchEvents()}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the event?</p>
          <button
            className="btn btn-danger"
            onClick={() => {
              handleDeleteEvent(selectedEvent);
              setShowDeleteModal(false);
            }}
          >
            Yes
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrganizerEventList;
