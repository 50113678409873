import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Modal, Image } from 'react-bootstrap';

const VenueDuplicateComponent = ({ selectedVenue, UserIDnumber, organizerFirstName, organizerLastName, onHide,onUpdate }) => {
  const [venueName, setVenueName] = useState('');
  const [venueDescription, setVenueDescription] = useState('');
  const [venueLocation, setVenueLocation] = useState('');
  const [venueZipcode, setVenueZipcode] = useState('');
  const [logoBase64, setLogoBase64] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [instagramURL, setInstagramURL] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [venueCount, setVenueCount] = useState(0);
  const venueLimit = 5; // Define your venue limit here

  function createImageUrl(base64String) {
    // Check if the base64 string already contains the prefix
    if (base64String.startsWith('data:image/')) {
        return base64String; // No need to modify
    }
    
    // Define known base64 prefix for JPEG images
    const jpegPrefix = 'data:image/jpeg;base64,';

    // Define known base64 prefixes for different image types
    const imageTypes = {
        'iVBORw0KGgo': 'png',  // PNG base64 prefix
        '/9j/4': 'jpeg',      // JPEG base64 prefix
        'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
    };

    // Detect image type
    let imageType = '';
    for (const [prefix, type] of Object.entries(imageTypes)) {
        if (base64String.startsWith(prefix)) {
            imageType = type;
            break;
        }
    }

    // If no known image type is found, assume JPEG
    if (!imageType) {
        imageType = 'jpeg';
    }

    // Return the data URL with the detected or default image type
    return `data:image/${imageType};base64,${base64String}`;
}

  useEffect(() => {
    const fetchVenueData = async () => {
      try {
        const response = await fetch(`http://34.232.96.254/GetVenueInformationByID.php?Venue_ID=${selectedVenue.Venue_ID}`);
        const data = await response.json();
        setVenueName(data.Venue_name);
        setVenueDescription(data.Venue_Description);
        setVenueLocation(data.Venue_address);
        setVenueZipcode(data.Venue_zipcode);
        setLogoBase64(data.Venue_Logo);
        setFacebookURL(data.Venue_FaceBook);
        setInstagramURL(data.Venue_Instagram);
        setYoutubeURL(data.Venue_Youtube);
        setTwitterURL(data.Venue_X_Twitter);
      } catch (error) {
        console.error('Error fetching venue data:', error);
      }
    };

    

    fetchVenueData();
    fetchVenueCount();
  }, [selectedVenue]);

  const fetchVenueCount = async () => {
    try {
      const response = await fetch(`http://34.232.96.254/GetTotalNumberOfVenues.php?organizerID=${UserIDnumber}`);
      const data = await response.json();
      console.log("CheckLimit:"+data.totalVenues);
      setVenueCount(data.totalVenues);
      return data.totalVenues; // Return the count to use in handleSaveVenue
    } catch (error) {
      console.error('Error fetching venue count:', error);
      return venueCount; // Fallback to the current state if fetching fails
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoBase64(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(file);
  };

  const handleSaveVenue = async () => {
    if (!venueName || !venueLocation || !venueZipcode || !venueDescription) {
      setShowErrorModal(true);
      return;
    }
    
    // Fetch the venue count and wait for it to complete
    const updatedVenueCount = await fetchVenueCount();
  
    if (updatedVenueCount >= venueLimit) {
      setShowLimitModal(true);
      return;
    }
  
    const updatedLocation = venueLocation;
    const requestBody = {
      Organizer_ID: UserIDnumber,
      Venue_name: venueName,
      Venue_email: selectedVenue?.Venue_email || '',
      Venue_Website: selectedVenue?.Venue_Website || '',
      Venue_registered_date: new Date().toISOString().split('T')[0],
      Organizer_first_name: organizerFirstName,
      Organizer_last_name: organizerLastName,
      Venue_city: selectedVenue?.Venue_city || '',
      Venue_address: updatedLocation,
      Venue_state: selectedVenue?.Venue_state || '',
      Venue_zipcode: venueZipcode,
      Venue_Country: selectedVenue?.Venue_Country || '',
      Venue_Phone: selectedVenue?.Venue_Phone || '',
      Venue_Mobile: selectedVenue?.Venue_Mobile || '',
      Venue_date_last_active: new Date().toISOString().split('T')[0],
      Venue_Description: venueDescription,
      Venue_Logo: logoBase64,
      Venue_FaceBook: facebookURL,
      Venue_Instagram: instagramURL,
      Venue_Youtube: youtubeURL,
      Venue_X_Twitter: twitterURL
    };
  
    try {
      const response = await fetch('http://34.232.96.254/CreateVenue.php', {
        method: 'POST',
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log('Venue duplicated successfully:', data);
      onUpdate();
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error duplicating venue:', error);
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    if (onHide) {
      onHide();
    }
  };
  
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleCloseLimitModal = () => setShowLimitModal(false);

  return (
    <Container style={{ paddingTop: '20px' }}>
      <Form>
        <Form.Group as={Row} controlId="venueName" className="mb-3">
          <Form.Label column sm={2}>Venue Name:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueName} onChange={(e) => setVenueName(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueDescription" className="mb-3">
          <Form.Label column sm={2}>Venue Description:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueDescription} onChange={(e) => setVenueDescription(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueLocation" className="mb-3">
          <Form.Label column sm={2}>Venue Location: Example: Street Address, City, State "FL":</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueLocation} onChange={(e) => setVenueLocation(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueZipcode" className="mb-3">
          <Form.Label column sm={2}>Venue Zipcode:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueZipcode} onChange={(e) => setVenueZipcode(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueLogo" className="mb-3">
          <Form.Label column sm={2}>Venue Logo:</Form.Label>
          <Col sm={10}>
            <Button variant="secondary" onClick={() => document.getElementById('fileInput').click()}>
              Select Logo
            </Button>
            <input id="fileInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleLogoUpload} />
            {logoBase64 && (
              <Image src={createImageUrl(logoBase64)} thumbnail style={{ marginTop: '10px', maxWidth: '200px' }} />
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="facebookURL" className="mb-3">
          <Form.Label column sm={2}>Facebook URL *Optional*:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={facebookURL} onChange={(e) => setFacebookURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="instagramURL" className="mb-3">
          <Form.Label column sm={2}>Instagram URL *Optional*:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={instagramURL} onChange={(e) => setInstagramURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="youtubeURL" className="mb-3">
          <Form.Label column sm={2}>YouTube URL *Optional*:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={youtubeURL} onChange={(e) => setYoutubeURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="twitterURL" className="mb-3">
          <Form.Label column sm={2}>X URL *Optional*:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={twitterURL} onChange={(e) => setTwitterURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Col sm={10}>
            <Button variant="primary" onClick={handleSaveVenue}>
              Save
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Venue Duplicated Successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Fill in the required information.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLimitModal} onHide={handleCloseLimitModal}>
        <Modal.Header closeButton>
          <Modal.Title>Limit Reached</Modal.Title>
        </Modal.Header>
        <Modal.Body>You have reached the maximum number of venues ({venueLimit}).</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLimitModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default VenueDuplicateComponent;

