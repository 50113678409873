import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import PaymentForm from '../Components/PaymentForm';
import ErrorModal from '../Components/ErrorModal';
import Modal from 'react-bootstrap/Modal';  // Assuming you are using react-bootstrap for modals

// ProcessingModal component
const ProcessingModal = ({ show }) => {
  return (
    <Modal show={show} centered>
      <Modal.Body style={{ textAlign: 'center' }}>
        <p>Processing...</p>
      </Modal.Body>
    </Modal>
  );
};

const PaymentScreen = ({ onPaymentSuccess, onPaymentFailure, customerInfo, priceId, isAccountDetails, EmailVerify, Processing, isProcessing, InputDetails, isOrganizerRegistration, onCustomerIdGenerated }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetails, setCardDetails] = useState(null);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkRegistered, setCheckRegister] = useState(false);

  const checkEmail = async () => {
    if (!EmailVerify) {
      return;
    }

    try {
      const response = await fetch(`http://34.232.96.254/CheckBandRegistrationEmail.php?email=${encodeURIComponent(EmailVerify)}`);
      const result = await response.json();
      setCheckRegister(result.success);
    } catch (error) {
      console.error('Error checking email:', error);
      setCheckRegister(false);
    }
  };

  const handlePayment = async () => {
    if (!InputDetails && !isOrganizerRegistration) {
      alert("Fill out all the details, Video and Audio are required");
      return;
    }

    if (!InputDetails && isOrganizerRegistration) {
      alert("Fill out all the details");
      return;
    }

    if (isProcessing) {
      // We will now show the modal instead of using alert
      return;
    }

    Processing(true);  // Start processing

    await checkEmail();
    if (checkRegistered) {
      alert("Email Already Registered");
      Processing(false);  // Stop processing
      return;
    }

    if (!cardDetails || !customerInfo) {
      alert("Customer Information is empty");
      Processing(false);  // Stop processing
      return;
    }

    try {
      if (!stripe || !elements) {
        throw new Error('Stripe has not been properly initialized.');
      }

      if (!cardDetails.complete) {
        throw new Error('Please provide complete card details.');
      }

      const customerData = await createCustomer(customerInfo);
      const customerId = customerData.id;

      console.log("CUSTOMER ID FROM PAYMENT SCREEN: "+customerId);

      // Pass the customerId to the parent component via prop
      onCustomerIdGenerated(customerId);

      const { paymentMethod, error: createPaymentError } = await createPaymentMethod(stripe, elements, {
        billingDetails: customerInfo,
      });

      if (createPaymentError) {
        throw createPaymentError;
      }

      if (isAccountDetails) {
        await updatePaymentMethod(customerId, paymentMethod.id);
      } else {
        await createSubscription(customerId, paymentMethod.id, priceId);
      }

      onPaymentSuccess();
    } catch (error) {
      console.error('Error handling payment:', error);
      setErrorMessage('Payment failed. Please try again. ' + error.message);
      setErrorModalVisible(true);
      onPaymentFailure('Payment failed. Please try again.');
    } finally {
      Processing(false);  // Stop processing
    }
  };

  const createCustomer = async (customerInfo) => {
    await checkEmail();
    if (checkRegistered) return;

    try {
      const response = await fetch('http://34.232.96.254/CreateCustomer.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerInfo),
      });

      const customerData = await response.json();
      return customerData;
    } catch (error) {
      console.error('Error creating customer:', error);
      throw error;
    }
  };

  const createSubscription = async (customerId, paymentMethodId, priceId) => {
    await checkEmail();
    if (checkRegistered) return;

    try {
      const response = await fetch('http://34.232.96.254/createSubscription.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId,
          priceId,
        }),
      });

      const subscriptionData = await response.json();
      if (subscriptionData.error) {
        throw new Error(subscriptionData.error);
      }

      return subscriptionData;
    } catch (error) {
      console.error('Error creating subscription:', error);
      throw error;
    }
  };

  const createPaymentMethod = async (stripe, elements, paymentMethodData) => {
    await checkEmail();
    if (checkRegistered) return;

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: paymentMethodData.billingDetails,
    });

    return { paymentMethod, error };
  };

  const updatePaymentMethod = async (customerId, paymentMethodId) => {
    try {
      const response = await fetch('http://34.232.96.254/UpdatePaymentMethod.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId,
        }),
      });

      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }

      return result;
    } catch (error) {
      console.error('Error updating payment method:', error);
      throw error;
    }
  };

  const hideErrorModal = () => {
    setErrorModalVisible(false);
    setErrorMessage('');
  };

  return (
    <div style={{ paddingTop: '25px' }}>
      <PaymentForm onCardChange={setCardDetails} />
      <div style={{ paddingTop: '25px', alignItems: 'center', paddingBottom: '100px' }}>
        <button onClick={handlePayment}>
          {isAccountDetails ? 'Update Card' : 'Register'}
        </button>
      </div>
      <ErrorModal show={errorModalVisible} onHide={hideErrorModal} errorMessage={errorMessage} />

      {/* Processing modal */}
      <ProcessingModal show={isProcessing} />
    </div>
  );
};

export default PaymentScreen;
