import React from 'react';

const GetUserInfo = (() => {
    const fetchUserEmailOfOrganizer = async (userId) => {
        try {
            const response = await fetch(`http://34.232.96.254/GetEmailFromOrganizerAccount.php?Organizer_ID=`+userId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // Omit the body property since you're passing user_id through the URL
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch user email');
            }
    
            const result = await response.json();
            if (result.Organizer_email) {
                return result.Organizer_email;
            } else {
                throw new Error('User email not found');
            }
        } catch (error) {
            throw new Error('Failed to fetch user email');
        }
    };

    const fetchUserEmailOfBand = async (userId) => {
      try {
          const response = await fetch(`http://34.232.96.254/GetEmailFromBandAccount.php?Band_ID=`+userId, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },
              // Omit the body property since you're passing user_id through the URL
          });
  
          if (!response.ok) {
              throw new Error('Failed to fetch user email');
          }
  
          const result = await response.json();
          if (result.Band_email) {
              return result.Band_email;
          } else {
              throw new Error('User email not found');
          }
      } catch (error) {
          throw new Error('Failed to fetch user email');
      }
  };

    const fetchUserNameForBand = async (userID) => {

      console.log("Fetch UserID:"+userID)
      try {
          const response = await fetch(`http://34.232.96.254/GetUsernameOfBand.php?Band_ID=`+userID);
  
          if (!response.ok) {
              throw new Error('Failed to fetch user name');
          }
  
          const result = await response.json();
  
          if (result.Band_name) {
              return result.Band_name;
          } else {
              throw new Error('User name not found');
          }
      } catch (error) {
          throw new Error('Failed to fetch user name');
      }
  };

  const fetchUserNameForOrganizer = async (userID) => {

    console.log("Fetch UserID:"+userID)
    try {
        const response = await fetch(`http://34.232.96.254/GetUsernameOfOrganizer.php?Organizer_ID=`+userID);

        if (!response.ok) {
            throw new Error('Failed to fetch user name');
        }

        const result = await response.json();

        if (result.Band_name) {
            return result.Band_name;
        } else {
            throw new Error('User name not found');
        }
    } catch (error) {
        throw new Error('Failed to fetch user name');
    }
};
    

  const fetchOrganizerEmail = async (eventID) => {

    console.log("Fetched EventID:"+eventID)
    try {
      const response = await fetch(`http://34.232.96.254/GetOrganizerEmailFromGig.php?Gig_id=${eventID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch organizer email');
      }

      const result = await response.json();
      if (result.Organizer_email) {
        return result.Organizer_email;
      } else {
        throw new Error('Organizer email not found');
      }
    } catch (error) {
      throw new Error('Failed to fetch organizer email');
    }
  };


  const fetchOrganizerIDFromGig = async (eventID) => {

    console.log("Fetched EventID:"+eventID)
    try {
      const response = await fetch(`http://34.232.96.254/GetOrganizerIDFromGig.php?Gig_id=${eventID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch organizer id');
      }

      const result = await response.json();
      if (result.Organizer_id) {
        return result.Organizer_id;
      } else {
        throw new Error('Organizer id not found');
      }
    } catch (error) {
      throw new Error('Failed to fetch organizer id');
    }
  };

  

  const fetchVenueDetailsFromGig = async (eventID) => {

    console.log("Fetched EventID:"+eventID)
    try {
      const response = await fetch(`http://34.232.96.254/GetVenueDetailsFromGig.php?Gig_id=${eventID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Venue Details');
      }

      const result = await response.json();
      if (result.Venue&&result.VenueID) {
        return result;
      } else {
        throw new Error('Venue Details not found');
      }
    } catch (error) {
      throw new Error('Failed to fetch venue Details');
    }
  };

  const fetchBandDetailsUsingID = async (userID) => {

    console.log("Fetched UserID:"+userID)
    try {
      const response = await fetch(`http://34.232.96.254/GetBandInformationByID.php?Band_ID=${userID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Band Details');
      }

      const result = await response.json();
      if (result.bandData) {
        return result.bandData;
      } else {
        throw new Error('Band Details not found');
      }
    } catch (error) {
      throw new Error('Failed to fetch Band Details');
    }
  };

  const fetchOrganizerDetailsUsingID = async (userID) => {

    console.log("Fetched UserID:"+userID)
    try {
      const response = await fetch(`http://34.232.96.254/GetOrganizerDetails.php?Organizer_ID=${userID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Organizer Details');
      }

      const result = await response.json();
      if (result) {
        return result;
      } else {
        throw new Error('Organizer Details not found');
      }
    } catch (error) {
      throw new Error('Failed to fetch Organizer Details');
    }
  };

  const GetBandEmailFromID = (userID) => {
    return fetchUserEmailOfBand(userID);
  };

  const GetOrganizerEmailFromID = (userID) => {
    return fetchUserEmailOfOrganizer(userID);
  };

  const GetUsernameFromIDForBand=(userID) =>{

    //Returns the Band Name as the User name

    return fetchUserNameForBand(userID);
  }

  const GetUsernameFromIDForOrganizer=(userID) =>{

    return fetchUserNameForOrganizer(userID);
  }

  const GetVenueDetailsForEvent = (eventID) => {
    return fetchVenueDetailsFromGig(eventID);
  };

  const GetBandDetailsByUserID = (userID) => {
    return fetchBandDetailsUsingID(userID);
  };

  const GetOrganizerDetailsByUserID = (userID) => {
    return fetchOrganizerDetailsUsingID(userID);
  };


  const GetOrganizerIDForEvent = (eventID) => {
    return fetchOrganizerIDFromGig(eventID);
  };

  const GetOrganizerEmailForEvent = (eventID) => {
    return fetchOrganizerEmail(eventID);
  };

  return {GetOrganizerEmailForEvent,GetUsernameFromIDForBand,GetUsernameFromIDForOrganizer,GetBandEmailFromID,GetOrganizerEmailFromID,GetOrganizerIDForEvent,GetVenueDetailsForEvent,GetBandDetailsByUserID,GetOrganizerDetailsByUserID };
})();

export default GetUserInfo;
