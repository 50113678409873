import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './BottomBarComponent.css';

const BottomBarComponent = ({ setView }) => {
  const [activeItem, setActiveItem] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const getContent = (view) => {
    switch (view) {
      case 'about':
        return { title: 'About Us', body: 'Helping musicians find gigs and gigs find musicians!' };
      case 'contact':
        return { title: 'Contact Us', body: 'For inquiries, email support@mygigslot.com or call +1 904-419-7701.' };
      case 'faqs':
        return { title: 'FAQs', body: 'Frequently Asked Questions about our service.' };
      case 'privacy-policy':
        return { title: 'Privacy Policy', body: 'Read our privacy policy to learn how we handle your data.' };
      case 'terms':
        return { title: 'Terms of Use', body: 'Our terms of use for all users and visitors.' };
      default:
        return { title: '', body: '' };
    }
  };

  // Handler to open modal for specific items
  const handleNavItemClick = (view) => {
    setActiveItem(view);

    if (['home', 'events', 'venues', 'band', 'gig-slot', 'post-event'].includes(view)) {
      setView(view); // Direct navigation for these items
      window.location.hash = view.toLowerCase();
    } else {
      setModalContent(getContent(view)); // Set modal content
      setShowModal(true); // Open modal
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="bottom-bar">
      <div className="section">
        <h4 className="section-title">Site Info</h4>
        <div className="underline"></div>
        <p className="section-text" style={{ textAlign: 'left' }}>
          Helping Musicians find gigs and gigs find musicians!<br />
          We make connections between gigs and bands!<br />
          We bring life to live entertainments!<br />
          Dream your life!<br />
          Love your life!<br />
          Live on your Dreams!
        </p>
      </div>

      <div className="section">
        <h4 className="section-title">Site Map</h4>
        <div className="underline"></div>
        <div className="bottombutton-group">
          <button className={`custom-button ${activeItem === 'home' ? 'active' : ''}`} onClick={() => handleNavItemClick('home')}>
            Home
          </button>
          <button className={`custom-button ${activeItem === 'about' ? 'active' : ''}`} onClick={() => handleNavItemClick('about')}>
            About Us
          </button>
          <button className={`custom-button ${activeItem === 'contact' ? 'active' : ''}`} onClick={() => handleNavItemClick('contact')}>
            Contact Us
          </button>
          <button className={`custom-button ${activeItem === 'faqs' ? 'active' : ''}`} onClick={() => handleNavItemClick('faqs')}>
            FAQs
          </button>
          <button className={`custom-button ${activeItem === 'privacy-policy' ? 'active' : ''}`} onClick={() => handleNavItemClick('privacy-policy')}>
            Privacy Policy
          </button>
          <button className={`custom-button ${activeItem === 'terms' ? 'active' : ''}`} onClick={() => handleNavItemClick('terms')}>
            Terms Of Use
          </button>
        </div>
      </div>

      <div className="section">
        <h4 className="section-title">Important Links</h4>
        <div className="underline"></div>
        <div className="bottombutton-group">
          <button className={`custom-button ${activeItem === 'events' ? 'active' : ''}`} onClick={() => handleNavItemClick('events')}>
            Events
          </button>
          <button className={`custom-button ${activeItem === 'venues' ? 'active' : ''}`} onClick={() => handleNavItemClick('venues')}>
            Venues
          </button>
          <button className={`custom-button ${activeItem === 'band' ? 'active' : ''}`} onClick={() => handleNavItemClick('band')}>
            Band
          </button>
          <button className={`custom-button ${activeItem === 'gig-slot' ? 'active' : ''}`} onClick={() => handleNavItemClick('gig-slot')}>
            Gig Slot
          </button>
          <button className={`custom-button ${activeItem === 'post-event' ? 'active' : ''}`} onClick={() => handleNavItemClick('post-event')}>
            Post an Event
          </button>
        </div>
      </div>

      <div className="section">
        <h4 className="section-title">Contact Info</h4>
        <div className="underline"></div>
        <div className="bottombutton-group">
          <button className="custom-button">12 Durbin Park, FL 12004, USA</button>
          <button className="custom-button">+1 904-419-7701</button>
          <button className="custom-button">Support@mygigslot.com</button>
        </div>
      </div>

      {/* Modal for displaying content */}
      <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header className="custom-modal-headerBottomBar" closeButton >
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BottomBarComponent;
