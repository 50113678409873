import React, { useState, useEffect } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap'; 
import { InfoCircle } from 'react-bootstrap-icons'; // Import an icon for the button
import './BandAccountProposalList.css'; // Import the CSS for styling

const BandAccountProposalList = ({ userid }) => {
    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [showModal, setShowModal] = useState(false); 
    const [proposalToDelete, setProposalToDelete] = useState(null); 
    const [showDetailsModal, setShowDetailsModal] = useState(false); // New state for the details modal
    const [selectedProposal, setSelectedProposal] = useState(null); // State to store the selected proposal

    useEffect(() => {
        if (userid) {
            fetchProposals(userid);
        }
    }, [userid]);

    const fetchProposals = async (userid) => {
        try {
            setLoading(true); 
            const response = await fetch(`http://34.232.96.254/GetProposalsByBandID.php?Band_id=${userid}`);
            const data = await response.json();
            setProposals(data);
        } catch (error) {
            console.error('Error fetching proposals:', error);
        } finally {
            setLoading(false); 
        }
    };

    const handleUnregisterClick = (proposalId) => {
        setProposalToDelete(proposalId); 
        setShowModal(true); 
    };

    const handleDeleteProposal = async () => {
        try {
            const response = await fetch(`http://34.232.96.254/DeleteProposalByID.php?Band_Proposal_id=${proposalToDelete}`);
            const result = await response.json();

            if (result.success) { 
                await fetchProposals(userid);
                setShowModal(false);
            } else {
                setShowModal(false);
                fetchProposals(userid);
            }
        } catch (error) {
            console.error('Error deleting proposal:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false); 
    };

    const handleShowDetailsModal = (proposal) => {
        setSelectedProposal(proposal); 
        setShowDetailsModal(true); 
    };

    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false); 
    };

    const proposalStatusFilter = (proposal) => {
        return proposal.Proposal_Status === "Accepted" ? "Accepted" : "Submitted";
    }

    const proposalDateFilter = (date) => {
        return date ? date : 'N/A';
    }

    return (
        <div className="bandproposal-list">
            {loading ? (
                <div className="loading-container">
                    <Spinner animation="border" variant="primary" />
                    <p>Loading proposals...</p>
                </div>
            ) : (
                proposals.length > 0 ? (
                    proposals.map((proposal) => (
                        <div className="bandproposal-card" key={proposal.Band_Proposal_id}>
                            <div className="bandproposal-details">
                                <div className="bandproposal-text">
                                    <strong>Gig:</strong> {proposal.Gig_Title}
                                </div>
                                
                                <div className="bandproposal-text">
                                    <strong>Date Registered:</strong> {proposalDateFilter(proposal.Date_Registered)}
                                </div>
                                <div className="bandproposal-text">
                                    <strong>Status:</strong> {proposalStatusFilter(proposal)}
                                </div>
                            </div>
                            <div className="proposal-actions">
                                 {/* Icon button to open the details modal */}
                                 <InfoCircle 
                                    className="info-icon" 
                                    onClick={() => handleShowDetailsModal(proposal)} 
                                />
                                <button
                                    className="unregister-button"
                                    onClick={() => handleUnregisterClick(proposal.Band_Proposal_id)}
                                >
                                    Unregister
                                </button>
                               
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No proposals found.</p>
                )
            )}

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Unregister</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to unregister the proposal?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleDeleteProposal}>
                        Yes, Unregister
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Details Modal */}
            <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Proposal Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedProposal && (
                        <>
                            <p><strong>Proposed Price:</strong> {selectedProposal.Proposed_Price}</p>
                            <p><strong>Proposed Message:</strong> {selectedProposal.Proposed_Message}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDetailsModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BandAccountProposalList;


