import React, { useState, useEffect  } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import SearchComponent from './SearchComponent';
import './EventCalendar.css'; // Import custom CSS
import GetUserInfo from '../GetUserInfo';

const localizer = momentLocalizer(moment);

const GetTextRegistration = (registrations, eventEndDate, gigStatus) => {
    return "Open";// For testing with gig proposal
 
   if (registrations == null || typeof registrations !== 'number') {
     console.log('Invalid registration data or null, running an odd patch that works');
     return registrations.RegistrationText;
   }
 
   if (gigStatus === "Canceled") {
     return "Canceled";
   }
 
   console.log('Get Text Registrations', registrations);
 
   const currentDate = moment(); // Get the current date
 
   if (moment(eventEndDate).isBefore(currentDate, 'day')) {
     return "Expired";
   } else if (registrations === 0) {
     return "Open";
   } else {
     return "Closed";
   }
 };
 
 const GetRegistrationBackgroundColor = (event, EventEndDate, GigStatus) => {

    return 'green'; //test
   if (GigStatus === "Canceled") {
     return "gray";
   }
   // If the event end date is in the past, return 'gray'
   const currentDate = moment(); // Get the current date
   if (moment(EventEndDate).isBefore(currentDate, 'day')) {
     return 'gray';
   } else {
     if (event.Gig_Confirmed_Registrations === 0) {
       return 'green';
     } else if (event.Gig_Confirmed_Registrations > 0) {
       return 'red';
     }
   }
 };

const CalendarComponent = ({ Role,AuthData }) => {
  const [events, setEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [selectedDistance, setSelectedDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [venueLogo, setVenueLogo] = useState(null);
  const [venueName, setVenueName] = useState('');
  const [venueDescription, setVenueDescription] = useState('');
  const [proposalPrice, setProposalPrice] = useState('');
  const [proposalMessage, setProposalMessage] = useState('');
  const [eventProposed, setEventProposed] = useState('');
  const ItemsPerPage = 10;
  //Role = 'subscriber';// for testing

  const [calendarState, setCalendarState] = useState({
    view: Views.MONTH, // Initial view mode
    date: moment(), // Initial current date
  });
 

  const fetchEvents = async (page = 1, query = '', location = '', distance = '') => {
    setLoading(true);
    try {
      let apiUrl = `http://34.232.96.254/GetGigsForBand.php?page=${page}&limit=${ItemsPerPage}`;
      
      if (query) apiUrl += `&keyword=${encodeURIComponent(query)}`;
      if (location) apiUrl += `&address=${encodeURIComponent(location)}&distance=${distance}`;
  
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      console.log('Fetched Data:', data); // Log fetched data for debugging
  
      if (data.gigs && Array.isArray(data.gigs)) {
        const newEvents = data.gigs.map(event => ({
          id: event.Gig_id,
          title: event.Gig_Title,
          start: moment(event.Gig_start_date).toDate(),
          end: moment(event.Gig_end_date).toDate(),
          description: event.Gig_Description,
          address: event.Gig_Location_Address,
          venueId: event.VenueID,
          photo: event.Gig_Image, // Assuming event.Gig_Image is base64 encoded PNG
          RegistrationStatus: parseInt(event.Gig_Confirmed_Registrations || 0, 10),
          RegistrationText: GetTextRegistration(parseInt(event.Gig_Confirmed_Registrations || 0, 10), moment(event.Gig_end_date).format('YYYY-MM-DD'), event.Gig_Status),
          RegistrationBackgroundColor: GetRegistrationBackgroundColor(event, moment(event.Gig_end_date).format('YYYY-MM-DD'), event.Gig_Status),
        }));
  
        console.log('New Events:', newEvents); // Log new events for debugging
  
        setEvents(newEvents); // Set events directly, replacing previous events
        
        // Navigate to the start date of the first event
        if (newEvents.length > 0) {
          const startDate = newEvents[0].start;
          handleNavigate(startDate); // Call handleNavigate to update the calendar view
        }
      } else {
        console.error('Unexpected response structure:', data);
        alert('Error: Unexpected response structure from server.');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      alert(`Error: Failed to fetch events. ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSearch = () => {
    setEvents([]);
    fetchEvents(1, searchQuery, searchLocation, selectedDistance);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.RegistrationBackgroundColor,
      color: 'white',
      borderRadius: '5px',
      border: 'none',
      padding: '5px',
      boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
      position: 'relative',
    };
  
    return {
      style,
      title: event.title,
      photo: event.photo,
      description: event.description,
      address: event.address,
    };
  };
  

  const handleProposalSubmit = async (event, user) => {
    try {
      console.log("Gig_id from Send Registration:"+event.id);
        // Get attendee name and email
       // const attendee_name = await GetUserInfo.GetUsernameFromIDForBand(user);
        //const attendee_email = await GetUserInfo.GetBandEmailFromID(user);

        // Get request by name and email
        //const request_by_name = event.name;
        //const request_by_email = await GetUserInfo.GetOrganizerEmailForEvent(event.id);

        const Organizer_ID =  await GetUserInfo.GetOrganizerIDForEvent(event.id);
        const VenueDetails = await GetUserInfo.GetVenueDetailsForEvent(event.id);
        const BandDetails =await GetUserInfo.GetBandDetailsByUserID(user); // band user id

        console.log("Registration DATA:"+registrationData);

        
        // Construct registration data
        const registrationData = {
          Gig_id: event.id, // Include event_id here
          Organizer_id:Organizer_ID,
          Band_id:user,
          Venue:VenueDetails.Venue,
          Venue_id:VenueDetails.VenueID,
          Gig_Title:event.name,
          Musician_first_name:BandDetails.Musician_first_name,
          Musician_last_name:BandDetails.Musician_last_name,
          Proposed_Price:proposalPrice,
          Proposed_Message:proposalMessage,
          Proposal_Status:"Submitted",
          Band_Email:BandDetails.Band_email,
          Gig_Name:event.name
          
    

        };

        console.log('Registration Data:', registrationData);

        const response = await fetch('http://34.232.96.254/SendProposal.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify(registrationData),
        });

        if (!response.ok) {
            throw new Error('Failed to send registration');
        }

        const result = await response.json();

        if (result.success) {
            console.log('Registration successful. Registration ID:', result.message);

            window.alert("Registration Sent");
            setEventProposed("Proposed");
            
        } else {
            console.error('Failed to register. Server response:', result.message);
        }

        /*

        if(result.event_id)
        {
          console.log('Registration event id received from php:',result.event_id);
        }

        if(result.meta_data)
        {
          console.log('Registration data received from php:',result.meta_data);
        }

        if(result._attendee_user_id)
        {
          console.log('Registration user id received from php:',result._attendee_user_id);
        }

       */

    
    } catch (error) {
        console.error('Error sending registration:', error.message);
    }
};

  // Event popup component
  const EventPopup = ({ event }) => {
    // Format time in 12-hour format (e.g., 12:00 PM)
    const formatTime = time => moment(time).format('h:mm A');

    return (
      <div style={{ maxWidth: '400px', background: '#ffffff', boxShadow: '0 0 10px rgba(0,0,0,0.1)', padding: '20px', borderRadius: '5px', color: '#333', textAlign: 'center' }}>
        <h3 style={{ color: '#333', fontSize: '18px', marginBottom: '10px', overflowWrap: 'break-word', maxWidth: '100%', textAlign: 'center' }}>
          {event.title}
        </h3>
        <p style={{ color: '#555', fontSize: '14px', marginBottom: '5px', overflowWrap: 'break-word', textAlign: 'center' }}>
          <strong>Start Time:</strong> {formatTime(event.start)}
        </p>
        <p style={{ color: '#555', fontSize: '14px', marginBottom: '5px', overflowWrap: 'break-word', textAlign: 'center' }}>
          <strong>End Time:</strong> {formatTime(event.end)}
        </p>
        {event.photo && (
          <img
            src={createImageUrl(event.photo)}
            alt={event.title}
            style={{
              maxWidth: '100%',
              height: 'auto', // Ensures image height adjusts based on width
              maxHeight: '200px', // Set a fixed max height for all images
              marginBottom: '10px',
              borderRadius: '5px',
              display: 'block', // Ensures the image is centered properly
              marginLeft: 'auto', // Centers the image horizontally
              marginRight: 'auto', // Centers the image horizontally
            }}
          />
        )}
        <p style={{ color: '#555', fontSize: '14px', marginBottom: '5px', maxHeight: '80px', overflowY: 'auto', overflowWrap: 'break-word', textAlign: 'center' }}>{event.description}</p>
        <p style={{ color: '#777', fontSize: '12px', marginBottom: '5px', maxHeight: '60px', overflowY: 'auto', overflowWrap: 'break-word', textAlign: 'center' }}>{event.address}</p>
      </div>
    );
  };

  const handleNavigate = (date, view) => {
    setCalendarState({
      date: moment(date), // Update the current date
      view: view || calendarState.view, // Update the view mode if provided, otherwise keep the current view
    });
  };
  

  // Handle event selection
  const handleSelectEvent = event => {
    console.log('Selected Event:', event);
    //setEventDetails(event);
    handleViewDetails(event);
    // Implement your logic here to show details or perform actions on event selection
    // For example, you can open a modal with more details about the event
  };

  // Render loading indicator if data is still loading
  if (loading) {
    return <div>Loading...</div>;
  }

  function incrementGigViews(gigId) {
    // URL of the PHP script
    const url = `http://34.232.96.254/Viewed.php?Gig_id=${gigId}`;
  
    // Fetch the URL with a GET request
    fetch(url)
        .then(response => {
            // Check if the response is successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Return the JSON response
            return response.json();
        })
        .then(data => {
            // Handle the JSON response data
            console.log(data);
            // You can further process the response if needed
        })
        .catch(error => {
            // Handle any errors
            console.error('There was a problem with the fetch operation:', error);
        });
  }
  
  const handleViewChange = (view) => {
    setCalendarState({
      ...calendarState,
      view: view
    });
  };

  const handleViewDetails = async (event) => {
    try {
      console.log("VenueID: " + event.venueId); // Check VenueID before fetch
      const response = await fetch(`http://34.232.96.254/GetVenueInformationByID.php?Venue_ID=${event.venueId}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const venueData = await response.json();
      console.log("Venue Data:", venueData); // Log fetched venue data for debugging
      
      // Check if Venue_Logo is present and valid base64 encoded string
      if (venueData.Venue_Logo&&venueData.Venue_name&&venueData.Venue_Description) {
        setVenueLogo(`${venueData.Venue_Logo}`); // Ensure correct base64 data URL format
        setVenueName(`${venueData.Venue_name}`);
        setVenueDescription(`${venueData.Venue_Description}`);
      } else {
        console.warn('Venue_Logo not found in venueData:', venueData);
      }
      incrementGigViews(event.id)
      setEventDetails(event);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching venue details:', error);
      alert(`Error: Failed to fetch venue details. ${error.message}`);
    }
  };

  function createImageUrl(base64String) {
    // Check if the base64 string already contains the prefix
    if (base64String.startsWith('data:image/')) {
        return base64String; // No need to modify
    }
    
    // Define known base64 prefix for JPEG images
    const jpegPrefix = 'data:image/jpeg;base64,';

    // Define known base64 prefixes for different image types
    const imageTypes = {
        'iVBORw0KGgo': 'png',  // PNG base64 prefix
        '/9j/4': 'jpeg',      // JPEG base64 prefix
        'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
    };

    // Detect image type
    let imageType = '';
    for (const [prefix, type] of Object.entries(imageTypes)) {
        if (base64String.startsWith(prefix)) {
            imageType = type;
            break;
        }
    }

    // If no known image type is found, assume JPEG
    if (!imageType) {
        imageType = 'jpeg';
    }

    // Return the data URL with the detected or default image type
    return `data:image/${imageType};base64,${base64String}`;
}

  // Render calendar once events are fetched
  return (
    <div style={{ maxWidth: '1400px', margin: '0 auto', backgroundColor: '#e2e6e9', padding: '20px' }}>
      <div style={{ textAlign: 'left', marginBottom: '20px' }}>
        <SearchComponent
          Role={Role}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchLocation={searchLocation}
          setSearchLocation={setSearchLocation}
          selectedDistance={selectedDistance}
          setSelectedDistance={setSelectedDistance}
          onSearch={handleSearch}
        />
      </div>
      <div className="calendar-container" style={{ height: '1700px', width: '100%' }}>
      <Calendar
  localizer={localizer}
  events={events}
  startAccessor="start"
  endAccessor="end"
  style={{ height: '100%', width: '100%', marginLeft: '0' }}
  eventPropGetter={eventStyleGetter}
  popup
  components={{
    event: EventPopup,
  }}
  onNavigate={handleNavigate} // Handle navigation to a specific date
  onSelectEvent={handleSelectEvent} // Handle event selection
  date={calendarState.date.toDate()} // Set date from calendarState
  view={calendarState.view} // Set view mode from calendarState
  views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
  onView={handleViewChange}
/>

      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>
      {eventDetails?.name}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {venueLogo && (
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <img
          src={createImageUrl(venueLogo)}
          alt="Venue Logo"
          style={{ maxHeight: '200px', maxWidth: '100%', objectFit: 'contain' }}
        />
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <h5>Venue Name: {venueName}</h5>
          <h5>Venue Description: {venueDescription}</h5>
        </div>
      </div>
    )}
    {eventDetails && (
      <>
        <h5>Start Time: {moment(eventDetails.start).format('h:mm A')}</h5>
        <h5>End Time: {moment(eventDetails.end).format('h:mm A')}</h5>
        <h5>Location: {eventDetails.address}</h5>
        <h5>Address: {eventDetails.address}</h5>
        <p>{eventDetails.description}</p>
        {eventDetails.address && (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <iframe
              title="Google Map"
              width="100%"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAF2Q8fA6MwI4ggg-uxaSnOk4tCeCZH7Ds&q=${encodeURIComponent(eventDetails.address)}`}
              allowFullScreen
            ></iframe>
          </div>
        )}
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    {Role == 'subscriber' && eventDetails?.RegistrationText == 'Open' && (
      <Button variant="secondary" onClick={() => setShowProposalModal(true)}>
        Proposal For The Gig
      </Button>
    )}
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

      <Modal show={showProposalModal} onHide={() => setShowProposalModal(false)} size="lg">
  <Modal.Header closeButton>
    <Modal.Title style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>{eventDetails?.name}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div style={{ marginTop: '20px', textAlign: 'center' }}>
      <h5>Proposal For The Gig:</h5>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Price:</label>
        <input type="text" value={proposalPrice} onChange={(e) => setProposalPrice(e.target.value)} />
      </div>
      <div style={{ marginBottom: '10px', textAlign: 'left' }}>
        <label>*Optional* Message:</label>
        <textarea
          rows="6" // Increased rows for a larger input area
          value={proposalMessage}
          onChange={(e) => setProposalMessage(e.target.value)}
          style={{ width: '100%', minHeight: '100px', marginTop: '5px' }} // Centered textarea with increased height
          placeholder="Enter your message here..." // Placeholder text
        />
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowProposalModal(false)}>
      Close
    </Button>
    <div>
                                {eventProposed && (
                                    <p style={{ color: 'red' }}>
                                        Note: You have already submitted a proposal for this event.
                                    </p>
                                )}
                            </div>
    <Button variant="primary" onClick={handleProposalSubmit(eventDetails,AuthData)}>
      Send
    </Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default CalendarComponent;




