import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap'; // Assuming you're using react-bootstrap for styling
import './Login.css'; // Import your custom CSS for Login styling
import { useAuth } from './AuthContext';
import UserProfile from './UserProfile'; // Import your UserProfile component
import { AlignCenter } from 'react-bootstrap-icons';

const Login = ({onLogin}) => {
  const [organizerEmail, setOrganizerEmail] = useState('');
  const [organizerPassword, setOrganizerPassword] = useState('');
  const [bandEmail, setBandEmail] = useState('');
  const [bandPassword, setBandPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const { authData, setAuthenticationData } = useAuth(); // Get authData and setAuthenticationData from context

  // Update isLoggedIn based on authData changes
  useEffect(() => {
    setIsLoggedIn(!!authData); // Convert authData to boolean
  }, [authData]);

  const handleOrganizerEmailChange = (e) => {
    setOrganizerEmail(e.target.value);
  };

  const handleOrganizerPasswordChange = (e) => {
    setOrganizerPassword(e.target.value);
  };

  const handleBandEmailChange = (e) => {
    setBandEmail(e.target.value);
  };

  const handleBandPasswordChange = (e) => {
    setBandPassword(e.target.value);
  };

  const handleLogin = async (loginType) => {
    try {
      let email, password;
      if (loginType === 'organizer') {
        email = organizerEmail;
        password = organizerPassword;
      } else if (loginType === 'band') {
        email = bandEmail;
        password = bandPassword;
      }
  
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
  
      const endpoint = loginType === 'organizer'
        ? 'http://34.232.96.254/OrganizerLogin.php'
        : 'http://34.232.96.254/BandLogin.php';
  
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseJson = await response.json();
      console.log('Server Response:', responseJson);
  
      if (responseJson.status) {
        const userData = responseJson.data;
  
        // Save user data to context and storage
        if (saveToStorage(userData)) {
          setAuthenticationData(userData);
  
          // Update login status
          setIsLoggedIn(true);
          onLogin();
          // No need to redirect, just update state for rendering
        } else {
          console.log('Failed to store auth');
        }
      } else {
        console.log('Authentication failed:', responseJson.msg);
        // Show authentication failed message
      }

      if(responseJson.status==false){
        alert(responseJson.msg);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      // Show fetch error message to the user
      // Example: Notify user about network or server errors
    }
  };
  

  const saveToStorage = (userData) => {
    if (userData) {
      localStorage.setItem(
        'authData',
        JSON.stringify({
          isLoggedIn: true,
          authToken: userData.auth_token,
          user_id: userData.user_id,
          user_login: userData.user_login,
          role: userData.role,
        })
      );
      return true;
    }
    return false;
  };

  // Render UserProfile component if logged in
  if (isLoggedIn) {
    return <UserProfile />;
  }

  // Render login forms if not logged in
  return (
    <Row className="login-row">
  <div style={styles.heading}> 
    <h4 style={{ fontSize: '30px', fontWeight: 'bold',marginLeft:'-100px' }}>Login</h4>
  </div>
  
  {/* Organizer Login Form */}
  <Col md={5}>
    <div className="login-box">
      <h4>Organizer Login</h4>
      <h4 style={{ fontSize: '20px', fontWeight: 'lighter' }}>Login to find your next gig!</h4>
      <Form onSubmit={(e) => { e.preventDefault(); handleLogin('organizer'); }}>
        <Form.Group controlId="organizerEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={organizerEmail}
            onChange={handleOrganizerEmailChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="organizerPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={organizerPassword}
            onChange={handleOrganizerPasswordChange}
            required
          />
        </Form.Group>

        <div style={styles.button}>
          <Button style={{ backgroundColor: 'orange', color: 'white', borderColor: 'orange' }} variant="primary" type="submit">
            Login as Organizer
          </Button>
        </div>
      </Form>
    </div>
  </Col>

  {/* Vertical Separator */}
  <Col md={1} className="vertical-separator">
    <div className="separator-line"></div>
  </Col>

  {/* Band Login Form */}
  <Col md={5}>
    <div className="login-box">
      <h4>Band Login</h4>
      <h4 style={{ fontSize: '20px', fontWeight: 'lighter' }}>Login to find your next gig!</h4>
      <Form onSubmit={(e) => { e.preventDefault(); handleLogin('band'); }}>
        <Form.Group controlId="bandEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={bandEmail}
            onChange={handleBandEmailChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="bandPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={bandPassword}
            onChange={handleBandPasswordChange}
            required
          />
        </Form.Group>

        <div style={styles.button}>
          <Button style={{ backgroundColor: 'orange', color: 'white', borderColor: 'orange' }} variant="primary" type="submit">
            Login as Band
          </Button>
        </div>
      </Form>
    </div>
  </Col>
</Row>

  );
};

const styles = {
  button: {
   marginTop: '10px',
  
  },
  heading: {
    marginTop: '20px',
    marginBottom: '20px',
    textAlign: 'center', // Center the heading
    fontSize: '36px', // Increase font size
    fontWeight: 'bold', // Make it bold (optional)
  },
}

export default Login;



